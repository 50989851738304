.carousel-logo {
    position: relative;
    padding: 30px 0;
    margin: 3px 0;
    overflow: hidden;
}

.carousel-logo:before {
    content: "";
    height: 382px;
    background: transparent;
    position: absolute;
    left: 150px;
    right: 150px;
    top: -385px;
    bottom: 0;
    box-shadow: 0 173px 99px -185px black;
    z-index: 100;
}

.carousel-logo-inner {
    margin: 0 auto;
    position: relative;
}

.carousel-logo-list {
    display: inline-block;
    position: relative;
    line-height: 0;
    font-size: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.carousel-initilized .carousel-logo-item {
    display: inline-block;
    visibility: visible;
}

.carousel-logo-item {
    display: inline-block;
    visibility: hidden;
    position: relative;
    line-height: 0;
    font-size: 0;
    text-align: center;
    vertical-align: middle;
}

.carousel-logo-link {
    display: block;
    padding-left: 10px;
}
