@import "../../../../styles/_settings.scss";
@import "../../../../styles/functions/_util.scss";
@import "../../../../styles/functions/_icons.scss";

.carousel {
    position: relative;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
    /*z-index: 1;*/
    &.carousel-initilized {
    }
}

.carousel-main-list {
    user-select: none;
}

.carousel-inner {
    overflow: hidden;
    position: relative;
}

.carousel-list {
    white-space: nowrap;
    font-size: 0;
    line-height: 0;
    transition-property: transform;
    transform: translate3d(0,0,0);
    overflow: hidden;
}

.carousel-item {
    backface-visibility: visible;
    transform: translateZ(0) scale(1.0, 1.0);
}

.carousel-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    text-align: center;
    margin-top: 10px;
    line-height: 1;
}

.carousel-dots-item {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    padding: rem-calc(5px);
    font-size: 0;
    line-height: 0;
    opacity: .5;
}

.carousel-dots-item-inner {
    border-radius: 50%;
    height: rem-calc(8px);
    width: rem-calc(8px);
    display: inline-block;
    box-shadow: 0 0 2px 0 rgba(0,0,0, .5);
}

.carousel-dots-selected {
    opacity: 1;
}

.carousel-nav-prev, .carousel-nav-next {
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent none;
    font-size: $font-size-xmedium;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:after {
        margin: 0;
    }

    &:disabled {
        display: none;
    }
}


.carousel-vertical {

    &.carousel-wrap-nav-outside {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    & > .carousel-nav-inside {
        .carousel-nav-prev {
            top: 0;
            transform: translateX(-50%);
            left: 50%;
        }

        .carousel-nav-next {
            bottom: 0;
            transform: translateX(-50%);
            left: 50%;
        }
    }

    & > .carousel-nav-outside {
        .carousel-nav-prev {
            bottom: 100%;
            transform: translateX(-50%);
            left: 50%;
        }

        .carousel-nav-next {
            top: 100%;
            transform: translateX(-50%);
            left: 50%;
        }
    }
}

.carousel-horizontal {

    &.carousel-wrap-nav-outside {
        margin-top: 2rem;
    }


    .carousel-nav-prev {
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }

    .carousel-nav-next {
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
    }

    .carousel-nav-inside {

        .carousel-nav-prev {
            left: 0;
        }

        .carousel-nav-next {
            right: 0;
        }
    }

    .carousel-nav-outside {
        position: absolute;
        bottom: 100%;
        right: rem-calc(10px);
        white-space: nowrap;

        .carousel-nav-prev, .carousel-nav-next {
            position: relative;
            width: 35px;
            left: auto;
            right: auto;
            top: auto;
            transform: translateY(0);
            display: inline-block;
            vertical-align: middle;


            &:disabled {
                opacity: .3;
            }
        }
    }


    .res-arrow-prev.carousel-nav-prev, .res-arrow-next.carousel-nav-next {
        top: rem-calc(-3px);
    }

    .res-arrow-prev.carousel-nav-prev {
        right: rem-calc(35px);
        left: auto;
    }

    .res-arrow-next.carousel-nav-next {
        right: rem-calc(10px);
    }
}


.carousel-mobile {
    padding: 0;
}

.carousel-update {
    visibility: hidden;

    /*.carousel-list {
        white-space: normal;
    }*/
}
.carousel--not-hidden-on-update.carousel-update{
    visibility: visible;
}

.carousel-nav-not-show > .carousel-nav {
    display: none;
}

.carousel-scroll-nav {
    .carousel-inner {
        overflow: auto;
    }
}
.carousel-placeholder {
    max-height: 1px;
    max-width: 1px;
}